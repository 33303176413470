import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';

import { useNotification, useLoading } from '@/modules/core';

const PAYMENTS_QUERY = gql`
  query balancePayments($businessId: ID!, $supplierId: ID) {
    payments(type: balance, initialBalance: true, businessId: $businessId, supplierId: $supplierId) {
      nodes {
        id
        supplierId
      }
    }
  }
`;

export const useBalancePayments = (variables, options) => {
  const { error } = useNotification();
  const { result, loading: queryLoading, onError, refetch } = useQuery(PAYMENTS_QUERY, variables, options);
  const paymentConnection = computed(() => result.value?.payments ?? { nodes: [], totalCount: 0 });
  const balancePayments = computed(() => paymentConnection.value.nodes);
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('payment - useBalancePayments', err);
    error();
  });

  return { balancePayments, loading, refetch };
};
