import { useMutation } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';

import { useNotification } from '@/modules/core';
import { onUnmounted } from 'vue';

const onPaymentCompleteDoneSubscribedCallbacks = [];
const onPaymentCompleteErrorSubscribedCallbacks = [];

export const usePaymentComplete = () => {
  const { error } = useNotification();

  const { mutate, loading, onDone, onError } = useMutation(PAYMENT_COMPLETE_MUTATION);

  onDone((result) => {
    onPaymentCompleteDoneSubscribedCallbacks.forEach((cb) => cb(result));
  });

  onError((param) => {
    onPaymentCompleteErrorSubscribedCallbacks.forEach((cb) => cb(param));
    console.error('useCompletePayment', param);
    error();
  });

  const onDoneCallbacks = [];
  const onErrorCallbacks = [];

  onUnmounted(() => {
    onDoneCallbacks.forEach((cb) =>
      onPaymentCompleteDoneSubscribedCallbacks.splice(onPaymentCompleteDoneSubscribedCallbacks.indexOf(cb), 1)
    );
    onErrorCallbacks.forEach((cb) =>
      onPaymentCompleteErrorSubscribedCallbacks.splice(onPaymentCompleteErrorSubscribedCallbacks.indexOf(cb), 1)
    );
  });

  return {
    completePayment: mutate,
    loading,
    onDone: (callback) => {
      onDoneCallbacks.push(callback);
      onPaymentCompleteDoneSubscribedCallbacks.push(callback);
    },
    onError: (callback) => {
      onErrorCallbacks.push(callback);
      onPaymentCompleteErrorSubscribedCallbacks.push(callback);
    },
  };
};

const PAYMENT_COMPLETE_MUTATION = gql`
  mutation paymentComplete($paymentId: ID!, $transactionId: ID!, $transactionParams: TransactionCompleteInput!) {
    paymentComplete(paymentId: $paymentId, transactionId: $transactionId, transactionParams: $transactionParams) {
      id
    }
  }
`;
