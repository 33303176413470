import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

export function usePatchTransaction(options) {
  const { mutate, loading, onDone, onError } = useMutation(PAYMENT_TRANSACTION_PATCH_MUTATION, options);

  return {
    patchTransaction: mutate,
    loading,
    onDone,
    onError,
  };
}

const PAYMENT_TRANSACTION_PATCH_MUTATION = gql`
  mutation paymentTransactionPatch(
    $paymentId: ID!
    $transactionId: ID!
    $transactionPatchParams: TransactionPatchInput!
  ) {
    paymentTransactionPatch(
      paymentId: $paymentId
      transactionId: $transactionId
      transactionPatchParams: $transactionPatchParams
    ) {
      id
      instructionNumber
      paymentMethod
      date
      reference
    }
  }
`;
