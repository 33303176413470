import { gql } from '@apollo/client/core';
import { computed } from 'vue';

import { useQuery, useResult } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

export function useActivityDocuments(variables) {
  const { error } = useNotification();

  const { result, loading, onError } = useQuery(ACTIVITY_DOCUMENTS_QUERY, variables, () => ({
    enabled: !!variables.value.businessId && !!variables.value.ids.length,
  }));
  const documentConnection = useResult(result, { nodes: [] });
  const documents = computed(() => documentConnection.value.nodes);

  onError((err) => {
    console.error('payment - useActivityDocuments', err);
    error();
  });

  return {
    documents,
    loading,
  };
}

const ACTIVITY_DOCUMENTS_QUERY = gql`
  query getActivityDocuments($businessId: ID!, $ids: [ID]) {
    documentsNew2(businessId: $businessId, ids: $ids) {
      nodes {
        id
        issueDate
        documentNumber
        type
      }
    }
  }
`;
