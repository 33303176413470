import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

export function useReconciliationApprovalDelete(options) {
  const { error } = useNotification();

  const { mutate, onDone, onError } = useMutation(RECONCILIATION_APPROVAL_DELETE_MUTATION, options);

  onError((err) => {
    console.error('useReconciliationApprovalDelete', err);
    error();
  });

  return {
    mutate,
    onDone,
  };
}

const RECONCILIATION_APPROVAL_DELETE_MUTATION = gql`
  mutation reconciliationApprovalDelete($id: ID!, $approvedBy: String) {
    reconciliationApprovalDelete(id: $id, approvedBy: $approvedBy) {
      approvedBy
      approvedAt
    }
  }
`;
