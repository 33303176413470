import { useMutation, useQuery } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';
import { reactive, computed } from 'vue';

import { useNotification, useLoading } from '@/modules/core';

const CREDIT_CARDS_QUERY = gql`
  query creditCards($businessId: ID!) {
    creditCards(businessId: $businessId) {
      nodes {
        id
        businessId
        cardOwner
        type
        number
      }
    }
  }
`;

export const useCreditCards = (businessId) => {
  const { error } = useNotification();
  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(CREDIT_CARDS_QUERY, { businessId }, () => ({
    enabled: !!businessId.value,
  }));
  const creditCards = computed(() => result?.value?.creditCards.nodes ?? []);
  const variables = reactive({ businessId });
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('useCreditCards', err);
    error();
  });

  return { creditCards, loading, refetch };
};

const CREDIT_CARD_CREATE_MUTATION = gql`
  mutation creditCardCreate($createParams: CreditCardCreateInput!) {
    creditCardCreate(createParams: $createParams) {
      id
      businessId
      cardOwner
      type
      number
    }
  }
`;

export const useCreateCreditCard = () => {
  const { error } = useNotification();

  const { mutate, loading, onDone, onError } = useMutation(CREDIT_CARD_CREATE_MUTATION);

  onError((err) => {
    console.error('useCreateCreditCard', err);
    error();
  });

  return {
    createCreditCard: mutate,
    loading,
    onDone,
    onError,
  };
};

const CREDIT_CARD_DELETE_MUTATION = gql`
  mutation creditCardDelete($id: ID!) {
    creditCardDelete(id: $id)
  }
`;

export const useDeleteCreditCard = () => {
  const { error } = useNotification();

  const { mutate, loading, onDone, onError } = useMutation(CREDIT_CARD_DELETE_MUTATION);

  onError((err) => {
    console.error('useDeleteCreditCard', err);
    error();
  });

  return {
    deleteCreditCard: mutate,
    loading,
    onDone,
  };
};
