import { gql } from '@apollo/client/core';
import { useQuery, useMutation } from '@vue/apollo-composable';
import { computed, reactive } from 'vue';

import { useNotification, useLoading } from '@/modules/core';
import { apolloClient } from '@/plugins/apollo-client';

export const useBankAccounts = (businessId) => {
  const { error } = useNotification();

  const {
    result,
    loading: queryLoading,
    refetch,
    onError,
  } = useQuery(BANK_ACCOUNTS_QUERY, { businessId }, () => ({
    enabled: !!businessId.value,
  }));

  const bankAccounts = computed(() => result?.value?.bankAccounts?.nodes ?? []);
  const variables = reactive({ businessId });
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('useBankAccounts', err);
    error();
  });

  return {
    bankAccounts,
    loading,
    refetch,
  };
};

export const BANK_ACCOUNTS_QUERY = gql`
  query bankAccounts($businessId: ID!) {
    bankAccounts(businessId: $businessId) {
      nodes {
        id
        businessId
        accountName
        bankNumber
        branchNumber
        accountNumber
        routingNumber
        filePath
        filePathUrl
        manualValidation
      }
    }
  }
`;

export const validateAccountChecksum = ({ bankNumber, branchNumber, accountNumber, routingNumber, businessId }) =>
  apolloClient
    .query({
      query: BANK_ACCOUNT_CHECKSUM_QUERY,
      variables: { bankNumber, branchNumber, accountNumber, routingNumber, businessId },
    })
    .then((result) => result.data.bankAccountValidateChecksum);

const BANK_ACCOUNT_CHECKSUM_QUERY = gql`
  query bankAccountValidateChecksum(
    $bankNumber: String
    $branchNumber: String
    $accountNumber: String
    $routingNumber: String
    $businessId: String!
  ) {
    bankAccountValidateChecksum(
      bankNumber: $bankNumber
      branchNumber: $branchNumber
      accountNumber: $accountNumber
      routingNumber: $routingNumber
      businessId: $businessId
    )
  }
`;

export const useBankAccountDelete = () => {
  const { error } = useNotification();
  const { mutate, onError, onDone } = useMutation(BANK_ACCOUNT_DELETE_MUTATION);

  onError((err) => {
    console.error('payment - useBankAccountDelete', err);
    error();
  });

  return {
    deleteBankAccount: mutate,
    onError,
    onDone,
  };
};

export const BANK_ACCOUNT_DELETE_MUTATION = gql`
  mutation bankAccountDelete($id: ID!) {
    bankAccountDelete(id: $id)
  }
`;

export const useBankAccountCreate = (variables) => {
  const { error } = useNotification();
  const { mutate, onDone, onError } = useMutation(BANK_ACCOUNT_CREATE_MUTATION, variables);

  onError((err) => {
    if (err.graphQLErrors[0]?.extensions?.response?.status === 409) throw err;
    console.error('useBankAccountCreate', err);
    error();
  });

  return {
    createBankAccount: mutate,
    onError,
    onDone,
  };
};

export const BANK_ACCOUNT_CREATE_MUTATION = gql`
  mutation bankAccountCreate($createParams: BankAccountCreateInput!) {
    bankAccountCreate(createParams: $createParams) {
      id
    }
  }
`;

export const getBankAccountFileUpload = (variables) =>
  apolloClient
    .query({ query: BANK_ACCOUNT_FILE_UPLOAD_QUERY, variables })
    .then((result) => result.data.bankAccountFileUpload);

export const BANK_ACCOUNT_FILE_UPLOAD_QUERY = gql`
  query bankAccountFileUpload($filename: String!, $businessId: ID!) {
    bankAccountFileUpload(filename: $filename, businessId: $businessId) {
      filePath
      url
      fields
    }
  }
`;

export const useSupportedBanks = (countryCode) => {
  const { error } = useNotification();

  const {
    result,
    loading: queryLoading,
    onError,
  } = useQuery(SUPPORTED_BANKS_QUERY, { countryCode }, () => ({
    enabled: !!countryCode.value,
  }));

  const supportedBanks = computed(() => {
    if (result.value?.bankAccountSupportedBanks) {
      return JSON.parse(result.value?.bankAccountSupportedBanks);
    }
    return {};
  });
  const variables = reactive(supportedBanks);
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('useSupportedBanks', err);
    error();
  });

  return {
    supportedBanks,
    loading,
  };
};

export const SUPPORTED_BANKS_QUERY = gql`
  query bankAccountSupportedBanks($countryCode: String!) {
    bankAccountSupportedBanks(countryCode: $countryCode)
  }
`;
