import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

export function useReconciliationApprovalCreate(options) {
  const { error } = useNotification();

  const { mutate, onDone, onError } = useMutation(RECONCILIATION_APPROVAL_CREATE_MUTATION, options);

  onError((err) => {
    console.error('useReconciliationApprovalCreate', err);
    error();
  });

  return {
    mutate,
    onDone,
  };
}

const RECONCILIATION_APPROVAL_CREATE_MUTATION = gql`
  mutation reconciliationApprovalCreate($id: ID!) {
    reconciliationApprovalCreate(id: $id) {
      approvedBy
      approvedAt
    }
  }
`;
