import { gql } from '@apollo/client/core';
import { useQuery, useResult } from '@vue/apollo-composable';
import { useNotification, useLoading } from '@/modules/core';

export function usePaymentTerms(variables, options) {
  const { error } = useNotification();

  const { result, loading: queryLoading, onError, refetch } = useQuery(TERMS_QUERY, variables, options);
  const terms = useResult(result, []);
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('usePaymentTerms', err);
    error();
  });

  return {
    terms,
    loading,
    refetch,
  };
}

export const TERMS_QUERY = gql`
  query terms($supplierId: ID, $businessId: ID) {
    terms(type: payment, supplierId: $supplierId, businessId: $businessId) {
      id
      businessId
      supplierId
      fromDate
      type: typeNew
      paymentMethod
      paymentDueNet
      beforeBilling
      directDebitDay
      directDebit
      bankAccountId
      eom
    }
  }
`;
