import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';

import { useNotification, useLoading } from '@/modules/core';

const UNBILLED_PAYMENTS_QUERY = gql`
  query unbilledPayments($businessId: ID!, $supplierId: ID, $paymentId: ID) {
    unbilledPayments(businessId: $businessId, supplierId: $supplierId, paymentId: $paymentId) {
      nodes {
        date
        paymentId
        supplierId
        paidAmount
        billedAmount
        createdBy {
          profile {
            firstName
            lastName
          }
        }
        createdAt
        billingLinks {
          amount
          billingId
          date
        }
        transactions {
          id
          paymentMethod
        }
      }
    }
  }
`;

export const useUnbilledPayments = (variables) => {
  const { error } = useNotification();
  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(UNBILLED_PAYMENTS_QUERY, variables, () => ({
    enabled: !!variables.value?.businessId,
  }));
  const unbilledPayments = computed(() => result?.value?.unbilledPayments?.nodes ?? []);
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('useUnbilledPayments', err);
    error();
  });

  return { unbilledPayments, loading, refetch };
};
