import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export const usePayables = (variables, options) => {
  const { error } = useNotification();
  const { result, loading, onError, refetch } = useQuery(PAYABLES_QUERY, variables, options);

  const payables = computed(() => result?.value?.payables?.nodes ?? []);

  onError((err) => {
    console.error('payment - usePayables', err);
    error();
  });

  return { payables, loading, onError, refetch };
};

export const PAYABLES_QUERY = gql`
  query payables($businessId: ID, $supplierId: ID, $toDate: ISODate) {
    payables(businessId: $businessId, supplierId: $supplierId, toDate: $toDate) {
      nodes {
        supplierId
        balance
      }
    }
  }
`;
