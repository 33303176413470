import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';

export function usePaymentTasks(variables) {
  const { error } = useNotification();

  const { result, loading: queryLoading, refetch, onError } = useQuery(TASKS_QUERY, variables);
  const totalCount = computed(() => result.value?.tasksNew2?.totalCount ?? 0);
  const tasks = computed(() => result.value?.tasksNew2?.nodes ?? []);
  const loading = useLoading(queryLoading, variables);

  onError((param) => {
    console.error('usePaymentTasks', param);
    error();
  });

  return {
    tasks,
    totalCount,
    loading,
    refetch,
  };
}

export const TASKS_QUERY = gql`
  query tasks($businessId: ID!, $type: String, $limit: Int, $offset: Int) {
    tasksNew2(
      domain: "payment"
      businessId: $businessId
      type: $type
      limit: $limit
      offset: $offset
      completed: false
    ) {
      totalCount
      nodes {
        id
        businessId
        data
      }
    }
  }
`;
