import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';

import { useNotification, useLoading } from '@/modules/core';

const UNPAID_BILLINGS_QUERY = gql`
  query unpaidBillings($businessId: ID, $supplierId: ID) {
    unpaidBillings(businessId: $businessId, supplierId: $supplierId) {
      nodes {
        billingId
        supplierId
        date
        totalAmount
        unpaidAmount
        paidAmount
      }
    }
  }
`;

export const useUnpaidBillings = (variables) => {
  const { error } = useNotification();
  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(UNPAID_BILLINGS_QUERY, variables, () => ({
    enabled: !!variables.value?.businessId || !!variables.value?.supplierId,
  }));
  const unpaidBillings = computed(() => result.value?.unpaidBillings?.nodes ?? []);
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('useUnpaidBillings', err);
    error();
  });

  return { unpaidBillings, loading, refetch };
};
