import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';

export function useUsers(ids) {
  const { error } = useNotification();
  const query = computed(() => createUserQuery(ids));
  const {
    result,
    loading: queryLoading,
    refetch,
    onError,
  } = useQuery(query, {}, () => ({ enabled: !!ids.value.length }));

  const users = computed(() =>
    Object.values(result.value ?? {}).reduce((usersHash, user) => {
      if (!user) return usersHash;
      return { ...usersHash, [user.id]: user };
    }, {})
  );

  const loading = useLoading(queryLoading, ids);

  onError((err) => {
    console.error('payment useUsers', err);
    error();
  });

  return {
    users,
    loadingUser: loading,
    refetch,
  };
}

const createUserQuery = (ids) => {
  if (!ids.value.length) return undefined;
  return gql`
    query user {
      ${ids.value.map(
        (id, index) => `user${index}: user(id: "${id}") {
          id
          firstName
          lastName
          profilePictureUrl
        }
      `
      )}
  }
`;
};
