import { prop } from 'ramda';
import { computed } from 'vue';

import { useUser } from '@/modules/auth';
import { useReconciliations, RECONCILIATIONS_QUERY } from '@/modules/reconciliation';

import { useCreatePayment } from './payment';
import { useCloseReconciliation } from './useCloseReconciliation';
import { useReconciliationApprovalCreate } from './useReconciliationApprovalCreate';
import { useReconciliationApprovalDelete } from './useReconciliationApprovalDelete';

export const PAYMENT_PAGE_CONTEXT = Symbol('Payment Page Context');

export function usePaymentState(variables) {
  const { user } = useUser();
  const { reconciliations, loading, refetch } = useReconciliations(
    variables,
    computed(() => ({ enabled: !!variables.value.businessId }))
  );

  const options = {
    update: (cache, { data: { closeReconciliation: closedReconciliation } }) => {
      const { reconciliations } = cache.readQuery({
        query: RECONCILIATIONS_QUERY,
        variables: variables.value,
      });

      cache.writeQuery({
        query: RECONCILIATIONS_QUERY,
        variables: variables.value,
        data: {
          reconciliations: {
            ...reconciliations,
            nodes: reconciliations.nodes.filter(({ id }) => id !== closedReconciliation.id),
          },
        },
      });
    },
  };

  const { mutate: closeReconciliation } = useCloseReconciliation(options);

  const {
    createPayment,
    loading: createPaymentLoading,
    onDone: createPaymentOnDone,
  } = useCreatePayment({
    update: (cache, { data: { paymentCreate: payment } }) => {
      const billingIds = payment.billingLinks.map(prop('billingId'));

      const { reconciliations } = cache.readQuery({
        query: RECONCILIATIONS_QUERY,
        variables: variables.value,
      });

      cache.writeQuery({
        query: RECONCILIATIONS_QUERY,
        variables: variables.value,
        data: {
          reconciliations: {
            ...reconciliations,
            nodes: reconciliations.nodes.filter(({ billedAmounts }) =>
              billedAmounts.every(({ billingId }) => !billingIds.includes(billingId))
            ),
          },
        },
      });
    },
  });

  const { mutate: approvalDelete } = useReconciliationApprovalDelete();
  const { mutate: approvalCreate } = useReconciliationApprovalCreate();

  const addApprovalToReconciliation = (reconciliation) => {
    const approvalToAdd = { approvedBy: user.value.id, approvedAt: new Date().getTime(), __typename: 'Approval' };
    reconciliation.approvals = reconciliation.approvals
      ? [...reconciliation.approvals, approvalToAdd]
      : [approvalToAdd];
  };

  const approvalCreateAndUpdateReconciliation = async (updatedReconciliation) => {
    const { data } = await approvalCreate({ id: updatedReconciliation.id });
    updatedReconciliation.approvals = data.reconciliationApprovalCreate;
  };

  const reconciliationApprovalCreate = async (id) => {
    const reconciliationToUpdate = reconciliations.value.find((reconciliation) => reconciliation.id === id);
    addApprovalToReconciliation(reconciliationToUpdate);
    approvalCreateAndUpdateReconciliation(reconciliationToUpdate);
  };

  const removeApprovalFromReconciliation = (reconciliation) => {
    reconciliation.approvals = reconciliation.approvals.filter((approval) => approval.approvedBy !== user.value.id);
  };

  const approvalDeleteAndUpdateReconciliation = async (updatedReconciliation) => {
    const { data } = await approvalDelete({ id: updatedReconciliation.id, approvedBy: user.value.id });
    updatedReconciliation.approvals = data.reconciliationApprovalDelete;
  };
  const reconciliationApprovalDelete = async (id) => {
    const reconciliationToUpdate = reconciliations.value.find((reconciliation) => reconciliation.id === id);

    removeApprovalFromReconciliation(reconciliationToUpdate);
    approvalDeleteAndUpdateReconciliation(reconciliationToUpdate);
  };

  return {
    reconciliations,
    loading,
    refetch,
    closeReconciliation,
    createPayment,
    createPaymentLoading,
    createPaymentOnDone,
    reconciliationApprovalCreate,
    reconciliationApprovalDelete,
  };
}
