import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { onUnmounted } from 'vue';

const onDoneSubscribedCallbacks = [];
const onErrorSubscribedCallbacks = [];

export const usePaymentPatch = () => {
  const { mutate, loading, onDone, onError } = useMutation(PAYMENT_PATCH_MUTATION);

  onDone((result) => {
    onDoneSubscribedCallbacks.forEach((cb) => cb(result));
  });

  onError((result) => {
    onDoneSubscribedCallbacks.forEach((cb) => cb(result));
  });

  const onDoneCallbacks = [];
  const onErrorCallbacks = [];

  onUnmounted(() => {
    onDoneCallbacks.forEach((cb) => onDoneSubscribedCallbacks.splice(onDoneSubscribedCallbacks.indexOf(cb), 1));
    onErrorCallbacks.forEach((cb) => onErrorSubscribedCallbacks.splice(onErrorSubscribedCallbacks.indexOf(cb), 1));
  });

  return {
    patchPayment: mutate,
    loading,
    onDone: (callback) => {
      onDoneCallbacks.push(callback);
      onDoneSubscribedCallbacks.push(callback);
    },
    onError: (callback) => {
      onErrorCallbacks.push(callback);
      onErrorSubscribedCallbacks.push(callback);
    },
  };
};

const PAYMENT_PATCH_MUTATION = gql`
  mutation patchPayment($id: ID, $patchParams: PaymentPatchInput!) {
    paymentPatch(id: $id, patchParams: $patchParams) {
      id
      billingLinks {
        amount
        billing {
          id
          date
          source {
            document: documentNew2 {
              id
              documentNumber
              type
            }
          }
        }
      }
    }
  }
`;
