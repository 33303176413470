import { gql } from '@apollo/client/core';
import { useQuery, useResult } from '@vue/apollo-composable';
import { computed } from 'vue';

import { useNotification } from '@/modules/core';

export const useBillings = (variables) => {
  const { error } = useNotification();
  const { result, loading, onError, onResult } = useQuery(BILLINGS_QUERY, variables, () => ({
    enabled: !!variables.value.ids?.length,
  }));
  const billings = computed(() => result.value?.billings?.nodes ?? []);

  onError((err) => {
    console.error('payment useBillings', err);
    error();
  });

  return { billings, loading, onResult };
};

const BILLINGS_QUERY = gql`
  query billings($ids: [ID!]!) {
    billings(ids: $ids) {
      nodes {
        id
        date
        totalAmount
        type
        eventReferences {
          document {
            id
            type
            documentNumber
          }
        }
      }
    }
  }
`;

export const useBillingsByMonth = (variables, options) => {
  const { error } = useNotification();
  const { result, onError } = useQuery(BILLINGS_BY_MONTH_QUERY, variables, options);
  const billings = computed(() => result.value?.billings?.nodes ?? []);

  onError((err) => {
    console.error('payment useBillingsByMonth', err);
    error();
  });

  return { billings };
};

const BILLINGS_BY_MONTH_QUERY = gql`
  query billings($businessId: ID, $supplierId: ID, $fromDate: ISODate, $toDate: ISODate) {
    billings(businessId: $businessId, supplierId: $supplierId, fromDate: $fromDate, toDate: $toDate) {
      nodes {
        id
        date
        totalAmount
      }
    }
  }
`;

const BILLINGS_BY_SUPPLIER = gql`
  query billingAggregation($businessId: ID) {
    billingAggregation {
      group(businessId: $businessId, groupBy: ["supplierId", "isoMonth"]) {
        supplierId
        count
        isoMonth
        totalAmount
      }
    }
  }
`;

export const useBillingsGroupedBySupplier = (variables, options) => {
  const { error } = useNotification();
  const { result, onError } = useQuery(BILLINGS_BY_SUPPLIER, variables, options);
  const billingsAggregations = useResult(result, []);

  onError((err) => {
    console.error('payment - useBillingsGroupedBySupplier', err);
    error();
  });

  return { billingsAggregations };
};

const BILLINGS_BY_BUSINESS = gql`
  query billingAggregation($supplierId: ID) {
    billingAggregation {
      group(supplierId: $supplierId, groupBy: ["businessId", "isoMonth"]) {
        businessId
        count
        isoMonth
        totalAmount
      }
    }
  }
`;

export const useBillingsGroupedByBusiness = (variables, options) => {
  const { error } = useNotification();
  const { result, onError } = useQuery(BILLINGS_BY_BUSINESS, variables, options);
  const billingsAggregations = useResult(result, []);

  onError((err) => {
    console.error('payment - useBillingsGroupedByBusiness', err);
    error();
  });

  return { billingsAggregations };
};

const BILLINGS_BY_BUSINESS_ONLY = gql`
  query billingAggregation($supplierId: ID) {
    billingAggregation {
      group(supplierId: $supplierId, groupBy: ["businessId"]) {
        business {
          id
          name
        }
        count
        isoMonth
        totalAmount
      }
    }
  }
`;

export const useBillingsGroupedByBusinessOnly = (variables, options) => {
  const { error } = useNotification();
  const { result, onError } = useQuery(BILLINGS_BY_BUSINESS_ONLY, variables, options);
  const billingsAggregations = useResult(result, []);

  onError((err) => {
    console.error('payment - useBillingsGroupedByBusiness', err);
    error();
  });

  return { billingsAggregations };
};
