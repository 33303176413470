import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core';

export const plaidLinkCreate = () => {
  const { error } = useNotification();
  const { mutate: createPlaidLink, onDone, onError } = useMutation(PLAID_LINK_CREATE_MUTATION);

  onError((err) => {
    console.error('plaidLinkCreate', err);
    error();
  });

  return { createPlaidLink, onDone };
};

const PLAID_LINK_CREATE_MUTATION = gql`
  mutation createPlaidLink {
    plaidLinkCreate {
      linkToken
    }
  }
`;

export const paymentMethodsConnectionCreate = () => {
  const { error } = useNotification();
  const { mutate: createPaymentMethodsConnection, onError } = useMutation(PAYMENT_METHODS_CONNECTION_CREATE_MUTATION);

  onError((err) => {
    console.error('paymentMethodsConnectionCreate', err);
    error();
  });

  return { createPaymentMethodsConnection };
};

const PAYMENT_METHODS_CONNECTION_CREATE_MUTATION = gql`
  mutation createPaymentMethodsConnection($createParams: PaymentMethodsConnectionCreateInput!) {
    paymentMethodsConnectionCreate(createParams: $createParams) {
      id
    }
  }
`;
